import { Atom } from ":mods";
import { createLanguageInfoInputs } from "../../form";
import { FormPageProps } from "../../model";
import { Show } from "solid-js";
import { STUDENT_FORM_STEPS } from "../../const";

export function LanguagePage(props: FormPageProps) {
  const FormInputs = createLanguageInfoInputs();
  const onSubmit = async () => {
    const values = FormInputs.Actions.getValuesLowercase();
    props.onFormSubmit(values);
  };
  return (
    <section class={`flex flex-col justify-center items-center bg#paper w-screen text-16px ${props.class ?? ""}`}>
      <Atom.CircleProgress.CircleProgressbar steps={STUDENT_FORM_STEPS} current_step={3} />
      <h1 class="text-28px font-medium my-40px w-630px ">Tell us about what language can you speak</h1>

      <form class="flex flex-col items-left gap-20px text-16px pt-40px w-630px">
        <div class="flex flex-col gap-10px">
          <FormInputs.speak_arabic.Label title="Do you speak Arabic?" class="mb-10px">
            <FormInputs.speak_arabic.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.speak_arabic.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.speak_arabic.Input type="radio" class="" placeholder="" name="speak_arabic" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.speak_arabic.Input type="radio" class="" placeholder="" name="speak_arabic" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <Show when={(FormInputs.speak_arabic.control.value as string) === "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.arabic_level.Label title="Select your level of Arabic" class="mb-10px">
              <FormInputs.arabic_level.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.arabic_level.Label>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.arabic_level.Input type="radio" class="" placeholder="" name="arabic_level" id="beginner" />
              <p class="text-16px">Beginner</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.arabic_level.Input
                type="radio"
                class=""
                placeholder=""
                name="arabic_level"
                id="intermediate"
              />
              <p class="text-16px">Intermediate</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.arabic_level.Input type="radio" class="" placeholder="" name="arabic_level" id="advanced" />
              <p class="text-16px">Advanced</p>
            </div>
          </div>
        </Show>

        <div class="flex flex-col gap-10px">
          <FormInputs.speak_english.Label title="Do you speak English?" class="mb-10px">
            <FormInputs.speak_english.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.speak_english.Label>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.speak_english.Input type="radio" class="" placeholder="" name="speak_english" id="true" />
            <p class="text-16px">Yes</p>
          </div>
          <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
            <FormInputs.speak_english.Input type="radio" class="" placeholder="" name="speak_english" id="false" />
            <p class="text-16px">No</p>
          </div>
        </div>
        <Show when={(FormInputs.speak_english.control.value as string) === "true"}>
          <div class="flex flex-col gap-10px">
            <FormInputs.english_level.Label title="Select your level of English" class="mb-10px">
              <FormInputs.english_level.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.english_level.Label>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.english_level.Input type="radio" class="" placeholder="" name="english_level" id="beginner" />
              <p class="text-16px">Beginner</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.english_level.Input
                type="radio"
                class=""
                placeholder=""
                name="english_level"
                id="intermediate"
              />
              <p class="text-16px">Intermediate</p>
            </div>
            <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
              <FormInputs.english_level.Input type="radio" class="" placeholder="" name="english_level" id="advanced" />
              <p class="text-16px">Advanced</p>
            </div>
          </div>
        </Show>
        <div class="flex justify-end gap-1rem">
          <Atom.Form.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            controls={[]}
            onclick={props.onBack}
            statusValid={"Back"}
          />
          <FormInputs.Actions.Button
            class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 w-35px h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
          />
        </div>
      </form>
    </section>
  );
}
